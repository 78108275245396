import { Currency, Pair, Token } from '@moonbiz/sdk'
import { Button, Text, useModal, Flex, Box, MetamaskIcon } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { registerToken } from 'utils/wallet'
import { isAddress } from 'utils'
import { useTranslation } from 'contexts/Localization'
import { WrappedTokenInfo } from 'state/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useGetBnbBalance } from 'hooks/useTokenBalance'
import { formatBigNumber, formatNumber } from 'utils/formatBalance'
import { useSattWallet } from 'state/satt/hooks'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { rp1PriceUsd } from 'state/farms/hooks'
import { URL_PROXY } from 'config/constants/endpoints'
import { CopyButton } from '../CopyButton'
import { Input as NumericalInput } from './NumericalInput'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`
const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })`
  padding: 0 0.5rem;
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
`
const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  z-index: 1;
`
const Container = styled.div`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.input};
  box-shadow: ${({ theme }) => theme.shadows.inset};
`
interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
}: CurrencyInputPanelProps) {
  const [priceCurrency, setPriceCurrency] = useState(0)
  const [dollarApprox, setDollarApprox] = useState(0)
  const { account, library } = useActiveWeb3React()
  const { sattAddress } = useSattWallet()
  const address = account || sattAddress || undefined
  const { balance: bnbBalance } = useGetBnbBalance()
  const selectedCurrencyBalance = useCurrencyBalance(address, currency ?? undefined)
  // const amountInDollar = useBUSDCurrencyAmount(
  //   //  showBUSD ? currency : undefined,
  //   currency,
  //   Number.isFinite(+value) ? +value : undefined,
  // )
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()

  const amountInDollars = (val) => {
    setDollarApprox(val * priceCurrency)
  }
  /* eslint-disable */

  const fetchCryptoPrice = async () => {
    const symbol = currency?.symbol
    setPriceCurrency(0)

    try {
      if (symbol !== 'RP1' && symbol !== undefined) {
        const response = await axios({
          url: '/api/cryptocurrency',
          method: 'GET',
          headers: {
            'X-CMC_PRO_API_KEY': '091586f4-11fe-4c6a-8c2b-059f5e75f7a7',
            'Content-Type': 'application/json',
          },
          params: {
            symbol: symbol,
          },
        })

        const price = response.data.data[symbol]?.quote?.USD?.price
        setPriceCurrency(price)
        amountInDollars(value)
      } else if (symbol === 'RP1') {
        const price = rp1PriceUsd()
        setPriceCurrency(price)
      }
    } catch (error) {
      console.error('Error fetching crypto price:', error)
    }
  }

  const token = pair ? pair.liquidityToken : currency instanceof Token ? currency : null
  const tokenAddress = token ? isAddress(token.address) : null

  useEffect(() => {
    fetchCryptoPrice()
    amountInDollars(value)
  }, [value, currency])

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
    />,
  )
  /* eslint-enable */

  return (
    <Box position="relative" id={id}>
      <Flex mb="6px" alignItems="center" justifyContent="space-between">
        <Flex>
          <CurrencySelectButton
            className="open-currency-select-button"
            selected={!!currency}
            onClick={() => {
              if (!disableCurrencySelect) {
                onPresentCurrencyModal()
              }
            }}
          >
            <Flex alignItems="center" justifyContent="space-between">
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
              ) : currency ? (
                <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
              ) : null}
              {pair ? (
                <Text id="pair" bold>
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </Text>
              ) : (
                <Text id="pair" bold>
                  {(currency && currency.symbol && currency.symbol.length > 20
                    ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                        currency.symbol.length - 5,
                        currency.symbol.length,
                      )}`
                    : currency?.symbol) || t('Select a currency')}
                </Text>
              )}
              {!disableCurrencySelect}
            </Flex>
          </CurrencySelectButton>
          {token && tokenAddress ? (
            <Flex style={{ gap: '4px' }} alignItems="center">
              <CopyButton
                width="16px"
                buttonColor="textSubtle"
                text={tokenAddress}
                tooltipMessage={t('Token address copied')}
                tooltipTop={-20}
                tooltipRight={40}
                tooltipFontSize={12}
              />
              {library?.provider?.isMetaMask && (
                <MetamaskIcon
                  style={{ cursor: 'pointer' }}
                  width="16px"
                  onClick={() =>
                    registerToken(
                      tokenAddress,
                      token.symbol,
                      token.decimals,
                      token instanceof WrappedTokenInfo ? token.logoURI : undefined,
                    )
                  }
                />
              )}
            </Flex>
          ) : null}
        </Flex>
        {address && (
          <Text
            onClick={onMax}
            color="textSubtle"
            fontSize="14px"
            style={{
              display: 'inline',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              maxWidth: '120px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {!hideBalance && !!currency
              ? t('Balance: %balance%', {
                  balance:
                    currency.symbol === 'BNB'
                      ? formatBigNumber(bnbBalance, 6)
                      : selectedCurrencyBalance?.toSignificant(6) ?? t('Loading'),
                })
              : ' -'}
          </Text>
        )}
      </Flex>
      <InputPanel>
        <Container as="label" style={{ display: 'block' }}>
          <LabelRow>
            <NumericalInput
              className="token-amount-input"
              value={value}
              onUserInput={(val) => {
                onUserInput(val)
              }}
            />
          </LabelRow>

          {!!currency && value !== '' && (
            <Flex justifyContent="flex-end" mr="1rem">
              <Flex maxWidth="200px">
                <Text fontSize="12px" color="textSubtle">
                  ~{formatNumber(dollarApprox)} USD
                </Text>
              </Flex>
            </Flex>
          )}
          <InputRow selected={disableCurrencySelect}>
            {address && currency && showMaxButton && label !== 'To' && (
              <Button onClick={onMax} scale="xs" variant="secondary">
                {t('Max').toLocaleUpperCase(locale)}
              </Button>
            )}
          </InputRow>
        </Container>
      </InputPanel>
    </Box>
  )
}
